section.content-cars {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -7px;
    padding: 3.2em 0 1.5em 0;
    background-color: #F0F0F0;
}

.ourcars-interactions {
    width: 92em;
    display: flex;
    justify-content: space-between;
}

section.cars {
    max-width: 90vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5em;
    justify-content: center;
}

.cars .ourcars-car {
    width: 280px;
    height: 415px;
    color: #fff;
    display: block;
    text-align: center;
    border-radius: 8px;
    background-color: #FFF;
    margin: 0 0.6em 1.2em 0.6em;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.12);

}

.cars .maincars-title {
    width: 80%;
    display: flex;
    position: relative;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: 1.8em 0 2.5em 0;
}

.cars .maincars-title .texts {
    position: absolute;
}

.cars button.ourcars-see-more-details {
    width: 90%;
    height: 35px;
    color: #FFF;
    border-radius: 4px;
    background: #E23233;
    transition: all 0.5s ease;
}

.cars button.ourcars-see-more-details:hover {
    color: #E23233;
    background: #FFF;
    box-shadow: 0px 0px 0px 2px #E23233 inset;
}

.cars .ourcars-car .ourcars-image {
    width: 100%;
    height: 185px;
    border-radius: 8px 8px 0px 0px;
    background-size: cover !important;
    background-position: center !important;
}

.cars .ourcars-data {
    display: flex;
    width: 270px;
    height: 118px;
    padding: 10px 10px 10px 15px;
    text-align: left;
    align-items: flex-start;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    flex-direction: column;
}

.cars .ourcars-brand {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
}

.cars .ourcars-model {
    color: #7C7B7B;
    font-size: 12px;
    font-weight: 500;
}

.cars .ourcars-value {
    color: #303030;
    font-size: 24px;
    font-weight: 600;
}

.cars .ourcars-others {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px 15px 5px 15px;
    color: #7C7B7B;
    font-size: 12px;
    justify-content: space-between;
    margin-top: 30px;
}

.content-cars .custom-select {
    width: auto !important;
    height: 57px;
    padding: .45rem 2.7rem .45rem .9rem;
    -moz-padding-start: calc(.9rem - 3px);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-position: right .9rem center;
    background-size: 14px 10px;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #252525;
    border-bottom: 2px solid #E23233;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

@media (max-width: 1662px) {
    .ourcars-interactions {
        width: 73.5em !important;
    }
}

@media (max-width: 1329px) {
    .ourcars-interactions {
        width: 54.7em !important;
    }
}

@media (max-width: 997px) {
    section.content-cars {
        padding: 1.5em 0 1.5em 0;
    }

    .ourcars-interactions {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        flex-direction: column;
    }

    .content-cars .custom-select {
        width: 36em !important;
        margin-top: 0.5em;
    }
}

@media (max-width: 664px) {
    section.cars {
        width: 90vh
    }

    .ourcars-interactions h1 {
        font-size: 26px !important;
    }

    .content-cars .custom-select {
        width: 80.5vw !important;
        font-size: 13px;
        height: 50px;
    }

    .cars .ourcars-data {
        width: 100% !important;
    }

    .cars .ourcars-car {
        width: 90%;
    }

    .cars button.ourcars-see-more-details {
        width: 92%;
    }
}