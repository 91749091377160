section.content-contact {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2.8em 0 2.2em 0;
}

section.contact-methods {
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1em;
}

section.content-contact .texts {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

button.social {
    width: 500px;
    height: 70px;
    display: flex;
    color: #E23233;
    font-size: 16px;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 1.2em;
    transition: 0.5s ease;
    justify-content: center;
    border: 2px solid #E23233;
}

button.social:hover {
    color: #FFF;
    background-color: #E23233;
}

button.social h4 {
    font-weight: 600;
    margin-left: 0.6em;
}

@media (max-width: 930px) {
    section.content-contact {
        margin: 1em 0 1em 0;
    }

    section.content-contact .texts h4 {
        font-size: 14px !important;
    }

    button.social {
        width: 100%;
        height: 80px;
        font-size: 14px;
        flex-direction: column;
    }

    button.social h4 {
        font-weight: 600;
        margin: 0.5em 0 0 0;
    }

    button.social .icon {
        font-size: 26px !important;
    }
}