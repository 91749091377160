section.content-main-carousel {
  width: 100%;
  height: 750px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

section.content-main-carousel .slick-dots {
  display: none !important;
}

section.content-main-carousel .slick-slide>div {
  margin: 0 !important;
}

section.content-main-carousel .slick-list {
  border-radius: 0;
}

.main-carousel-images {
  width: 100%;
  height: auto;
}

.main-carousel-partner {
  width: 100vw !important;
  align-items: center;
  object-fit: cover !important;
  display: flex !important;
}

.main-carousel-image {
  width: 100%;
  height: 750px;
  margin: auto;
  object-fit: cover !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

@media (min-width: 1400px) {
  .car-page .main-carousel-partner {
    position: relative;
    background-repeat: no-repeat !important;
  }

  .car-page .main-base-carousel-image {
    width: 100%;
    height: 750px;
    position: absolute;
    object-fit: cover !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
  }

  .car-page .main-carousel-image {
    position: relative;
    object-fit: contain !important;
    background-size: contain !important;
  }
}


@media (max-width: 1000px) {
  section.content-main-carousel {
    height: 480px;
  }

  .main-carousel-images {
    height: 480px;
  }

  .main-carousel-image {
    height: 480px;
  }
}

@media (max-width: 620px) {
  section.content-main-carousel {
    height: 310px;
  }

  .main-carousel-images {
    height: 310px;
  }

  .main-carousel-image {
    height: 310px;
  }

  .main-custom-next-arrow,
  .main-custom-prev-arrow {
    display: none !important;
  }
}

@media (max-width: 545px) {
  section.content-main-carousel {
    padding: 0;
  }
}

.main-custom-next-arrow,
.main-custom-prev-arrow {
  top: 50%;
  z-index: 1;
  opacity: 0.5;
  display: flex;
  cursor: pointer;
  color: #E23233;
  font-size: 2.5rem;
  user-select: none;
  position: absolute;
  background-color: #FFF;
  border-radius: 20em;
  transition: opacity 1s ease, transform 1s ease;
}

.main-custom-next-arrow:hover,
.main-custom-prev-arrow:hover {
  opacity: 1;
}

.main-custom-next-arrow {
  padding: 8px 8px 8px 10px;
  right: 50px !important;
}

.main-custom-prev-arrow {
  padding: 8px 10px 8px 8px;
  left: 50px !important;
}

/* Style Product Data */
.content-pageproduct {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pageproduct-main {
  width: 80%;
  display: flex;
  padding: 1em 0 0 0;
  transition: 0.5s ease;
  justify-content: space-between;
}

.pageproduct-other {
  width: 100%;
  display: flex;
  padding: 2.5em 0 0 0;
  align-items: center;
  transition: 0.5s ease;
  flex-direction: column;
}

.product-brand {
  color: #E23233;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
}

.product-model {
  color: #252525;
  font-size: 26px;
  font-weight: 500;
  margin-top: -12px;
}

.product-value {
  color: #252525;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  margin-top: -7px;
}

.contact-seller {
  display: flex;
  width: 350px;
  height: 65px;
  font-size: 21px;
  margin-top: 15px;
  font-weight: 600;
  color: #E23233;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 3px solid #E23233;
  transition: 0.5s ease;
}

.contact-seller:hover {
  color: #FFF;
  background-color: #E23233;
}

.base-info {
  width: 100%;
  display: flex;
  padding: 2.1em 0 1em 0;
  justify-content: center;
  background-color: #F0F0F0;
}

.base-details {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.4em 0 0.4em;
  transition: 0.5s ease;
  justify-content: space-between;
}

.base-details div {
  width: 180px;
}

.base-details div.cambio {
  width: 410px;
}

.base-label {
  width: 80%;
  margin-top: -1em;
  position: absolute;
  transition: 0.5s ease;
  display: flex !important;
}

.label-title {
  display: flex;
  width: auto;
  height: 37px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  padding: 0 1em 0 1em;
  border-radius: 10px;
  background: #E23233;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.base-info h1 {
  color: #252525;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.base-info h4 {
  color: #252525;
  font-size: 20px;
  font-weight: 600;
  margin-top: -2px;
  text-transform: uppercase;
}

.base-info h3 {
  color: #252525;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 1560px) {
  .pageproduct-main,
  .base-details,
  .base-label {
    width: 85%;
  }
}

@media (max-width: 1330px) {
  .base-option-others {
    flex: 0 0 50% !important;
  }
}

@media (max-width: 1073px) {
  .base-details.main {
    justify-content: flex-start;
  }

  .base-details.main div {
    margin-bottom: 5px;
  }
}

@media (max-width: 1230px) {
  .product-brand {
    margin-top: 1vh;
    line-height: 50px;
  }

  .product-model {
    margin-top: 0.5vh;
  }
}

@media (max-width: 1150px) {
  .product-brand {
    font-size: 38px;
  }

  .product-model {
    font-size: 20px;
  }

  .product-value {
    font-size: 40px;
  }

  .contact-seller {
    width: 300px;
    font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .base-option-others {
    flex: 0 0 100% !important;
  }
}

@media (max-width: 875px) {
  .pageproduct-main {
    flex-direction: column;
  }

  .product-brand {
    margin-top: 0;
    font-size: 34px;
    line-height: 35px;
  }

  .product-model {
    font-size: 16px;
    margin-top: 0;
  }

  .product-value {
    font-size: 35px;
    margin-top: 1vh;
  }

  .contact-seller {
    width: 100%;
    height: 60px;
    font-size: 16px;
    margin-top: 8px;
  }
}

@media (max-width: 600px) {
  .base-info h3,
  .base-details h6 {
    font-size: 16px !important;
    font-weight: 600;
  }
}

@media (max-width: 875px) {
  .product-brand {
    font-size: 29px;
  }

  .product-model {
    font-size: 15px;
  }

  .product-value {
    font-size: 32px;
  }
}