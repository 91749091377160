section.content-maincars {
    width: 100%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 54px 0 90px 0;
}

.maincars-cars {
    width: 75%;
}

.maincars-car {
    width: 266px;
    height: 414px;
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.12);
    color: #fff;
    display: flex;
    text-align: center;
    justify-content: center;
}

.maincars-title {
    width: 80%;
    display: flex;
    position: relative;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 1.8em 0 2.5em 0;
}

.maincars-title .maincars-texts {
    position: absolute;
}

.slick-slide>div {
    margin: 1em 1.2em 1em 0;
}

.slick-slide {
    height: auto !important;
}

.slick-track {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: stretch !important;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
    transition: all 0.5s ease;
    color: #E23233 !important;
}

.content-maincars button.see-all-cars {
    right: 0;
    width: 180px;
    height: 34px;
    flex-shrink: 0;
    border-radius: 6px;
    color: #E23233;
    font-size: 14px;
    font-weight: 600;
    background: #FFF;
    transition: all 1s ease;
    margin-top: -1.3em;
    position: absolute;
    box-shadow: 0px 0px 0px 2px #E23233 inset;
}

.content-maincars button.see-all-cars:hover {
    color: #FFF;
    background: #E23233;
}

button.see-more-details {
    width: 250px;
    height: 35px;
    color: #FFF;
    border-radius: 4px;
    background: #E23233;
    transition: all 0.5s ease;
}

button.see-more-details:hover {
    color: #E23233;
    background: #FFF;
    box-shadow: 0px 0px 0px 2px #E23233 inset;
}

.maincars-car .maincars-image {
    width: 100%;
    height: 185px;
    border-radius: 8px 8px 0px 0px;
    background-size: cover !important;
    background-position: center !important;
}

.maincars-data {
    display: flex;
    width: 270px;
    height: 118px;
    padding: 10px;
    text-align: left;
    align-items: flex-start;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    flex-direction: column;
}

.maincars-brand {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
}

.maincars-model {
    color: #7C7B7B;
    font-size: 12px;
    font-weight: 500;
}

.maincars-value {
    color: #303030;
    font-size: 24px;
    font-weight: 600;
}

.maincars-others {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px 10px 5px 10px;
    color: #7C7B7B;
    font-size: 12px;
    justify-content: space-between;
    margin-top: 30px;
}

.custom-next-arrow,
.custom-prev-arrow {
    top: 50%;
    cursor: pointer;
    color: #E23233;
    position: absolute;
}

.slick-list {
    border-radius: 8px;
}

.maincars-texts h1 {
    font-weight: 400 !important;
}

@media (max-width: 1100px) {
    .content-maincars button.see-all-cars {
        display: none;
    }

    .custom-next-arrow,
    .custom-prev-arrow {
        display: none;
    }

    .maincars-cars {
        width: 80%;
    }

    .maincars-title {
        padding: 1.8em 0 1.8em 0;
    }
}

@media (max-width: 930px) {
    section.content-maincars {
        padding: 50px 0 70px 0;
    }

    .maincars-texts h1 {
        font-size: 26px !important;
    }

    .maincars-title {
        padding: 1em 0 1em 0;
    }
}

@media (max-width: 545px) {
    .maincars-title {
        padding: 1em;
    }

    section.content-maincars {
        padding: 18px 0 55px 0;
    }
}