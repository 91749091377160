section.content-ourpartners {
    width: 100%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0 80px 0;
}

.ourpartners-partners {
    width: 75%;
    height: auto;
}

.ourpartners-partner {
    width: 300px !important;
    height: 200px;
    align-items: center;
    object-fit: cover !important;
    display: flex !important;
}

.ourpartners-title {
    width: 80%;
    display: flex;
    justify-content: center;
}

.ourpartners-image {
    width: 90%;
    height: 90%;
    margin: auto;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

@media (max-width: 1100px) {
    .ourpartners-partners {
        width: 80%;
    }
}

@media (max-width: 545px) {
    section.content-ourpartners {
        padding: 18px 0 55px 0;
    }
}