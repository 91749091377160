.content-searchcar {
    overflow: hidden;
    max-width: 100%;
    height: auto;
    display: flex;
    margin: 0 auto;
    margin-top: -7px;
    padding: 52px 0 70px 0;
    flex-direction: column;
    justify-content: center;
    background-color: #F0F0F0;
}

.content-searchcar .texts {
    color: #252525;
    text-align: center;
}

.texts h1,
.texts h2,
.texts h3 {
    font-weight: 400;
}

form.searchcar {
    display: flex;
    align-items: center;
    flex-direction: column;
}

form .custom-select {
    display: block;
    width: 100%;
    height: 57px;
    padding: .45rem 2.7rem .45rem .9rem;
    -moz-padding-start: calc(.9rem - 3px);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    background-color: #FFF;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .9rem center;
    background-size: 14px 10px;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #252525;
    border-bottom: 2px solid #E23233;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}

form .form-selects {
    display: flex;
    flex-direction: row;
}

form.searchcar label {
    color: #252525;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    margin-left: 3vh;
    margin-top: 2.5vh;
    flex-direction: column;
}

button.send-searchcar {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    width: 185px;
    height: 57px;
    flex-shrink: 0;
    margin-top: 2vh;
    border-radius: 8px;
    background: #E23233;
    transition: all 0.8s ease;
}

button.send-searchcar:hover {
    color: #E23233;
    background: #FFF;
    box-shadow: 0px 0px 0px 2px #E23233 inset;
}

.content-searchcar button.see-all-cars {
    display: none;
}

@media (max-width: 1100px) {
    .content-searchcar {
        height: auto;
        padding: 4.2em;
    }

    form.searchcar {
        width: 100%;
        margin: auto;
    }

    form .form-selects {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    form .custom-select {
        width: 100% !important;
    }

    form.searchcar label {
        margin-top: 1.2vh;
        margin-left: 0;
    }

    button.send-searchcar {
        width: 100%;
        font-size: 16px;
    }

    .content-searchcar button.see-all-cars {
        width: 100%;
        height: 45px;
        display: block;
        border-radius: 8px;
        color: #E23233;
        font-size: 14px;
        font-weight: 600;
        background: #FFF;
        margin-top: 1em;
        box-shadow: 0px 0px 0px 2px #E23233 inset;
        transition: all 1s ease;
    }

    .content-searchcar button.see-all-cars:hover {
        color: #FFF;
        background: #E23233;
    }
}

@media (max-width: 820px) {
    .content-searchcar {
        padding: 3.2em 1.8em 3.2em 1.8em;
    }

    .texts h1 {
        font-size: 32px !important;
    }

    .texts h2,
    .texts h3 {
        font-size: 16px !important;
    }
}

@media (max-width: 545px) {
    .content-searchcar {
        padding: 1.6em 1.8em 2em 1.8em;
    }

    .texts h1 {
        font-size: 23px !important;
    }

    .texts h2,
    .texts h3 {
        font-size: 14px !important;
    }

    form.searchcar label,
    form .custom-select {
        font-size: 14px;
    }
}