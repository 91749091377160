section.content-about {
    width: 100%;
    height: auto;
    display: flex;
    margin: 3.2em 0 3.2em 0;
    align-items: center;
    flex-direction: column;
}

.about-ourstory,
.about-ourteam {
    width: 80%;
    display: flex;
    padding: 0.2em 0 1em 0;
    justify-content: space-between;
}

.about-reverse {
    flex-direction: row-reverse;
    text-align: start !important;
}

.about-image,
.about-text {
    width: 49%;
}

.about-image {
    height: 350px;
    border-radius: 8px;
}

.about-text {
    display: flex;
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    text-align: end;
}

.about-image.ourstory {
    display: flex;
    background-position-x: center !important;
    background-position-y: 90% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.about-image.ourteam {
    display: flex;
    background-position-x: center !important;
    background-position-y: 10% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

@media (max-width: 930px) {
    section.content-about {
        margin: 1em 0 1em 0;
        justify-content: center !important;
    }

    .about-ourstory,
    .about-ourteam {
        display: flex;
        text-align: right;
        justify-content: center;
        flex-direction: column;
    }

    .about-image,
    .about-text {
        width: 100%;
        text-align: justify !important;
    }

    .about-text {
        margin-top: 0.5em;
        font-size: 14px;
    }

    .texts h1 {
        font-size: 26px !important;
    }
}