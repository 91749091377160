section.content-ourqualities {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #F0F0F0;
    transition: all 1s ease;
}

.ourqualities-data {
    width: 80%;
    display: flex;
    padding: 4em 0 4em 0;
    align-items: center;
    justify-content: center;
}

.ourqualities-item {
    width: 25%;
    height: 140px;
    margin: 0 10px;
}

.ourqualities-icon {
    width: 52px;
    height: 52px;
    margin-bottom: 8px;
    background-size: cover !important;
    background-position: center !important;
}

.ourqualities-title {
    font-size: 22px;
    font-weight: 500;
    color: #E23233;
}

.ourqualities-description {
    font-size: 12px;
    font-weight: 400;
    color: #252525;
}

@media (max-width: 1415px) {
    .ourqualities-data {
        display: flex;
        flex-wrap: wrap;
        padding: 2em 0 2em 0;
    }

    .ourqualities-item {
        min-width: 370px;
        margin-top: 10px;
    }
}

@media (max-width: 975px) {
    .ourqualities-data {
        text-align: center;
        padding: 1.8em 0 0.8em 0;
    }

    .ourqualities-item {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}