.header {
  width: 100%;
  height: 34px;
  background-color: #E23233;
}

.header .content {
  max-width: 80%;
  height: 34px;
  color: #FFF;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}

button.place {
  display: flex;
  color: #FFF;
  align-items: center;
}

.place .streetAndCity {
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.4em;
}

.socials {
  height: 100%;
  display: flex;
  align-items: center;
}

.socials .icone-social {
  display: flex;
  color: #FFF;
  margin-right: 0.2em;
}

@media (max-width: 1050px) {
  .header .content {
    max-width: 90%;
  }
}

@media (max-width: 660px) {
  .header {
    display: none;
  }
}