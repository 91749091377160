.slider {
  display: block;
  position: relative;
}

.image {
  width: 100%;
  height: 650px;
  object-fit: cover;
  pointer-events: none;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 2.5rem;
  color: #FFF;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  transition: opacity 1s ease, transform 1s ease;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 2.5rem;
  color: #FFF;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  transition: opacity 1s ease, transform 1s ease;
}

.right-arrow:hover,
.left-arrow:hover {
  opacity: 1;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
}

.main-caraousel-slick .slick-dots {
  margin-bottom: 30px;
}

.main-caraousel-slick .slick-slide>div {
  margin: 0;
}

.main-caraousel-slick .slick-list {
  border-radius: 0;
}

@media (max-width: 660px) {
  .right-arrow,
  .left-arrow {
    font-size: 1.6rem;
  }
}