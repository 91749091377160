.menu {
  width: 100%;
  height: 78px;
  background-color: #FFF;
}

.menu-icons {
  display: none;
}

.menu .content {
  max-width: 80%;
  height: 78px;
  margin: auto;
  padding: 1.5em;
  display: flex;
  align-items: center;
  position: relative;
}

.menu .sub-content {
  right: 0;
  display: flex;
  position: absolute;
  align-items: center;
  padding-right: 1.5em;
}

.menu #logo {
  pointer-events: none;
  width: 100px;
  margin-top: 7px;
}

#btnFastContact {
  color: #E23233;
  text-align: center;
  font: 600 normal 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 238px;
  height: 37px;
  border-radius: 10px;
  border: 2px solid #E23233;
  transition: 0.5s ease;
}

#btnFastContact:hover {
  color: #FFF;
  background-color: #E23233;
}

.menu nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: 1.1vh;
}

.menu nav li {
  padding: 2.5vh;
}

.menu nav a {
  font-size: 14px;
  font-weight: 400;
  display: block;
  color: #1E1E1E;
  opacity: 0.7;
  transition: 0.5s;
  position: relative;
  text-decoration: none;
}

.menu nav a:hover {
  opacity: 1;
  color: #E23233;
}

.menu nav a::after {
  content: "";
  background-color: #E23233;
  position: absolute;
  width: 0%;
  height: 0.3vh;
  bottom: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  margin-bottom: -3vh;
}

.menu nav a:hover::after{
  width: 100%;
}

@media (max-width: 1050px) {
  .menu {
    padding: 0 2.3vh 0 2.3vh;
    height: 91px;
  }

  .menu .content {
    max-width: 90%;
    height: 91px;
    display: flex;
    padding: 0;
  }

  .menu-icons {
    right: 0;
    cursor: pointer;
    display: flex;
    color: #FFF;
    padding: 1.6vh;
    font-size: 1.5rem;
    position: absolute;
    border-radius: 20px;
    background-color: #E23233;
  }

  .menu nav ul {
    display: block;
    padding-top: 0.8vh;
  }

  .menu nav li {
    padding: 0 0 20px 12px;
  }

  .menu .sub-content {
    opacity: 0;
    display: none;
    position: fixed;
    top: 125.4px;
    width: 300px;
    height: 100vh;
    background: #FFF;
    box-shadow: -10px 0px 16.4px -11px rgba(0, 0, 0, 0.10);
    padding: 10px 0 0 10px;
  }

  .menu .sub-content.active {
    z-index: 1 !important;
    opacity: 1;
    display: block;
    transition: all 1s ease;
  }

  .menu nav a::after {
    margin-bottom: -1vh;
  }

  .menu nav a:hover::after{
    width: 95.5%;
  }

  .menu-close {
    display: none;
  }

  #btnFastContact {
    width: 280px;
    display: block;
    color: #FFF;
    background-color: #E23233;
  }
}

@media (max-width: 660px) {
  .menu .sub-content {
    top: 90.5px;
  }
}