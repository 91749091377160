@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
  background-color: #FFF;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

header {
  width: 100%;
  top: 0;
  left: 0;
}